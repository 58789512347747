import { role } from '@/constants/config'
import i18n from '@/i18n'

export const menuItems = [
  {
    id: 99,
    icon: 'fal fa-chart-simple',
    label: i18n.t('menu.vmb-menu.dashboard'),
    to: '/vmb'
  },
  {
    id: 3,
    icon: 'fal fa-handshake',
    label: i18n.t('menu.vmb-menu.what-went-well'),
    to: '/vmb/what-went-well'
  },
  {
    id: 4,
    icon: 'fal fa-microphone',
    label: i18n.t('menu.vmb-menu.communications'),
    to: '/vmb/communication'
  },
  {
    id: 5,
    icon: 'fal fa-envelope-open-text',
    label: i18n.t('menu.vmb-menu.feedback'),
    to: '/vmb/feedback'
  },
  {
    id: 6,
    icon: 'fal fa-gauge-high',
    label: i18n.t('menu.vmb-menu.performance'),
    to: '/vmb/performance'
  },
  {
    id: 1,
    icon: 'fal fa-headset',
    label: i18n.t('menu.vmb-menu.problems'),
    to: '/vmb/problems'
  },
  {
    id: 2,
    icon: 'fal fa-lightbulb-on',
    label: i18n.t('menu.vmb-menu.actions'),
    to: '/vmb/actions'
  },
  {
    id: 7,
    icon: 'fal fa-people-simple',
    label: i18n.t('menu.vmb-menu.people'),
    to: '/vmb/people'
  }
]
// export default data;

export const adminMenuItems = [
  {
    id: 1,
    icon: 'simple-icon-game-controller',
    label: 'Game Control',
    to: '/admin/game-control',
    role: [role.admin, role.leader]
  },
  {
    id: 2,
    icon: 'simple-icon-rocket',
    label: 'Score Centre',
    to: '/admin/scores',
    role: [role.admin, role.leader]
  },
  {
    id: 3,
    icon: 'fa fa-book',
    label: 'Audit Log',
    to: '/admin/audit-log',
    role: [role.admin, role.leader]
  },
  {
    id: 4,
    icon: 'simple-icon-settings',
    label: 'Management',
    to: '/admin/management/teams',
    role: [role.admin, role.leader]
  }
]

export const topMenuItems = [
  {
    id: 1,
    label: i18n.t('menu.home'),
    to: '/'
  },
  {
    id: 2,
    label: i18n.t('menu.rules'),
    to: '/rules'
  },
  {
    id: 3,
    label: i18n.t('menu.policies'),
    to: '/policies'
  },
  {
    id: 4,
    label: i18n.t('menu.systems'),
    to: '/lodgements'
  },
  {
    id: 5,
    label: i18n.t('menu.vmb'),
    to: '/vmb'
  }
]

export const managementTabs = [
  {
    id: 1,
    label: 'Teams',
    to: '/admin/management/teams',
    order: 1,
    role: [role.admin, role.leader]
  },
  {
    id: 2,
    label: 'Users',
    to: '/admin/management/users',
    order: 2,
    role: [role.admin, role.leader]
  },
  {
    id: 3,
    label: 'Approval Codes',
    to: '/admin/management/approval-codes',
    order: 3,
    role: [role.admin]
  },
  {
    id: 4,
    label: 'Claim Master',
    to: '/admin/management/claim-master',
    order: 4,
    role: [role.admin]
  }
]
