var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      staticClass: "user-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onUserSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "col-12 mb-4 text-center" },
            [
              _c("b-avatar", {
                staticClass: "mb-2 avatar-edit",
                attrs: {
                  button: "",
                  "badge-variant": "transparent",
                  variant: "light",
                  size: "5em",
                  src: _vm.avatarUrl,
                },
                on: { click: _vm.onAvatarClick },
                scopedSlots: _vm._u(
                  [
                    _vm.avatarUrl
                      ? {
                          key: "badge",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "simple-icon-camera" }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _vm.fullName.trim()
                ? _c("h5", [_vm._v(_vm._s(_vm.fullName))])
                : _c("h5", [_vm._v("[Name]")]),
              _vm._v(" "),
              _c("input", {
                ref: "avatarFile",
                attrs: {
                  id: "fileUpload",
                  type: "file",
                  accept: "image/jpg,image/png",
                  hidden: "",
                },
                on: { change: _vm.onAvatarFileChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-md-6 mb-3" },
            [
              _c("base-input", {
                attrs: {
                  label: "First Name:",
                  placeholder: "First Name",
                  validate: "",
                  required: "",
                  v: _vm.$v.newUser.firstName,
                  error: _vm.setError(_vm.$v.newUser.firstName, "First Name"),
                  loading: _vm.loadingUser,
                },
                model: {
                  value: _vm.newUser.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.newUser, "firstName", $$v)
                  },
                  expression: "newUser.firstName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-md-6 mb-3" },
            [
              _c("base-input", {
                attrs: {
                  label: "Last Name:",
                  placeholder: "Last Name",
                  validate: "",
                  required: "",
                  v: _vm.$v.newUser.lastName,
                  error: _vm.setError(_vm.$v.newUser.lastName, "Last Name"),
                  loading: _vm.loadingUser,
                },
                model: {
                  value: _vm.newUser.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.newUser, "lastName", $$v)
                  },
                  expression: "newUser.lastName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-md-6 mb-3" },
            [
              _vm.hasLeaderRole
                ? _c("base-input", {
                    attrs: {
                      label: "Team:",
                      placeholder: "Team",
                      validate: "",
                      required: "",
                      readonly: "",
                      v: _vm.$v.newUser.team,
                      error: _vm.setError(_vm.$v.newUser.team, "Team"),
                      loading: _vm.loadingUser,
                    },
                    model: {
                      value: _vm.selectedTeam.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectedTeam, "name", $$v)
                      },
                      expression: "selectedTeam.name",
                    },
                  })
                : _c("base-selection", {
                    staticClass: "team-selection",
                    attrs: {
                      label: "Team:",
                      placeholder: "Team",
                      itemValue: "name",
                      options: _vm.allTeams,
                      validate: "",
                      required: "",
                      readonly: "",
                      v: _vm.$v.newUser.team,
                      error: _vm.setError(_vm.$v.newUser.team, "Team"),
                      loading: _vm.loadingUser,
                    },
                    model: {
                      value: _vm.selectedTeam,
                      callback: function ($$v) {
                        _vm.selectedTeam = $$v
                      },
                      expression: "selectedTeam",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-md-6 mb-3" },
            [
              _c("base-selection", {
                staticClass: "role-selection",
                attrs: {
                  label: "Role:",
                  placeholder: "Role",
                  itemValue: "name",
                  options: _vm.roles,
                  validate: "",
                  required: "",
                  readonly: "",
                  v: _vm.$v.newUser.role,
                  error: _vm.setError(_vm.$v.newUser.role, "Role"),
                  loading: _vm.loadingUser,
                },
                model: {
                  value: _vm.selectedRole,
                  callback: function ($$v) {
                    _vm.selectedRole = $$v
                  },
                  expression: "selectedRole",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-md-12 mb-3" },
            [
              _c("base-input", {
                attrs: {
                  label: "Email:",
                  placeholder: "Email",
                  validate: "",
                  required: "",
                  readonly: _vm.editMode,
                  type: "email",
                  v: _vm.$v.newUser.email,
                  error: _vm.setError(_vm.$v.newUser.email, "Email"),
                  loading: _vm.loadingUser,
                },
                model: {
                  value: _vm.newUser.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.newUser, "email", $$v)
                  },
                  expression: "newUser.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.resetPassword
                ? _c(
                    "b-col",
                    { staticClass: "col-md-6 mb-3" },
                    [
                      _c("base-input", {
                        attrs: {
                          label: "Password:",
                          placeholder: "Password",
                          validate: "",
                          required: "",
                          type: "password",
                          v: _vm.$v.newUser.password,
                          error: _vm.setError(
                            _vm.$v.newUser.password,
                            "Password"
                          ),
                          loading: _vm.loadingUser,
                        },
                        model: {
                          value: _vm.newUser.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.newUser, "password", $$v)
                          },
                          expression: "newUser.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.resetPassword
                ? _c(
                    "b-col",
                    { staticClass: "col-md-6 mb-3" },
                    [
                      _c("base-input", {
                        attrs: {
                          label: "Confirm Password:",
                          placeholder: "Confirm Password",
                          validate: "",
                          required: "",
                          type: "password",
                          v: _vm.$v.newUser.confirmPassword,
                          error: _vm.setError(
                            _vm.$v.newUser.confirmPassword,
                            "Confirm Password"
                          ),
                          loading: _vm.loadingUser,
                        },
                        model: {
                          value: _vm.newUser.confirmPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.newUser, "confirmPassword", $$v)
                          },
                          expression: "newUser.confirmPassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "align-self-center" },
          [
            _vm.editMode
              ? _c(
                  "b-form-checkbox",
                  {
                    staticClass: "align-items",
                    attrs: {
                      id: "reset-password-checkbox",
                      name: "reset-password-checkbox",
                      value: true,
                      "unchecked-value": false,
                    },
                    model: {
                      value: _vm.resetPassword,
                      callback: function ($$v) {
                        _vm.resetPassword = $$v
                      },
                      expression: "resetPassword",
                    },
                  },
                  [_vm._v("\n        Reset password?\n      ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "align-self-center ml-auto" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "light", size: "sm" },
                on: { click: _vm.onCancelClick },
              },
              [_c("span", { staticClass: "label" }, [_vm._v("Cancel")])]
            ),
            _vm._v(" "),
            _c("processing-button", {
              staticClass: "ml-2",
              attrs: {
                variant: "primary",
                size: "sm",
                label: _vm.editMode ? "Update" : "Save",
                processing: _vm.processing,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }