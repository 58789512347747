var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c("div", { staticClass: "d-flex mb-2" }, [
        _c(
          "div",
          { staticClass: "align-self-center" },
          [
            _c("base-input", {
              staticClass: "search-user-input",
              attrs: { placeholder: "Search user...", type: "search" },
              model: {
                value: _vm.filter,
                callback: function ($$v) {
                  _vm.filter = $$v
                },
                expression: "filter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "align-self-center ml-auto" },
          [
            _c(
              "b-button",
              {
                staticClass: "text-uppercase mr-2",
                attrs: { size: "sm", variant: "danger" },
                on: { click: _vm.onNewUserClick },
              },
              [_vm._v("Add New User")]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "text-uppercase",
                attrs: { size: "sm", variant: "outline-danger" },
                on: { click: _vm.onUserImportClick },
              },
              [_vm._v("Import Users")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-colxx",
            { attrs: { xxs: "12" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-4 stats-table", attrs: { title: "Users" } },
                [
                  _vm.allUsers
                    ? _c("action-table", {
                        key: _vm.reloadUserTable,
                        attrs: {
                          items: _vm.allUsers,
                          fields: _vm.userDataFields,
                          perPage: 6,
                          filter: _vm.filter,
                          filterOn: _vm.filterOnFields,
                          hasActions: "",
                          hasUnblockAction: "",
                          actionTooltip: "full_name",
                        },
                        on: {
                          itemUnblocked: _vm.onItemUnblock,
                          itemClicked: _vm.onItemClick,
                          itemDeleted: _vm.onItemDelete,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "hide-header": "",
            "hide-footer": "",
            "content-class": "user-edit-modal",
          },
          model: {
            value: _vm.showNewModal,
            callback: function ($$v) {
              _vm.showNewModal = $$v
            },
            expression: "showNewModal",
          },
        },
        [
          _c("user-form", {
            on: { userSaved: _vm.onUserSave, userCancelled: _vm.onUserCancel },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "hide-header": "",
            "hide-footer": "",
            "content-class": "user-edit-modal",
          },
          model: {
            value: _vm.showEditModal,
            callback: function ($$v) {
              _vm.showEditModal = $$v
            },
            expression: "showEditModal",
          },
        },
        [
          _c("user-form", {
            attrs: { editMode: "", userId: _vm.selectedUserId },
            on: { userSaved: _vm.onUserSave, userCancelled: _vm.onUserCancel },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "userImportModal",
          attrs: {
            id: "userImportModal",
            "hide-header": "",
            "hide-footer": "",
          },
        },
        [
          _c("h3", { staticClass: "mb-4" }, [_vm._v("Import users")]),
          _vm._v(" "),
          _c(
            "b-form",
            {
              staticClass: "av-tooltip tooltip-label-bottom",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onUserImportSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12 mb-4" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("label", { staticClass: "pl-0 mb-2 col-12" }, [
                            _vm._v("Select user file: "),
                          ]),
                          _vm._v(" "),
                          _c("b-form-file", {
                            attrs: {
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            },
                            on: { change: _vm.onFileChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: {
                            variant: "primary",
                            size: "lg",
                            type: "submit",
                          },
                        },
                        [_c("span", { staticClass: "label" }, [_vm._v("Save")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("action-confirm-modal", {
        key: _vm.reloadActionTable,
        staticClass: "delete-user-modal",
        attrs: {
          actionButton: "Delete",
          actionButtonVariant: "danger",
          showModal: _vm.showConfirmModal,
          processing: _vm.processing,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showConfirmModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showConfirmModal = $event
          },
          modalTrigger: _vm.onModalTrigger,
          actionConfirmed: _vm.onActionConfirm,
          actionCanceled: _vm.onActionCancel,
        },
        scopedSlots: _vm._u(
          [
            _vm.deletedUser
              ? {
                  key: "body",
                  fn: function () {
                    return [
                      _c("h6", [
                        _vm._v(
                          "\n        Are you sure you want to delete\n        "
                        ),
                        _c("span", { staticClass: "deleted-user-name" }, [
                          _vm._v(_vm._s(_vm.deletedUser.full_name)),
                        ]),
                        _vm._v("?\n      "),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("This action cannot be undone once confirmed."),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }