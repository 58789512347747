var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar" }, [
    _c(
      "div",
      { staticClass: "main-menu" },
      [
        _c(
          "vue-perfect-scrollbar",
          {
            staticClass: "scroll",
            attrs: {
              settings: { suppressScrollX: true, wheelPropagation: false },
            },
          },
          [
            _c(
              "ul",
              { staticClass: "list-unstyled" },
              _vm._l(_vm.menuItems, function (item, index) {
                return _c(
                  "li",
                  {
                    key: `menu-${index}`,
                    class: { active: _vm.selectedMenuId === item.id },
                    on: {
                      click: function ($event) {
                        return _vm.onMenuClick(item.id)
                      },
                    },
                  },
                  [
                    _c("router-link", { attrs: { to: item.to, custom: "" } }, [
                      _c(
                        "span",
                        { staticClass: "menu-link", attrs: { role: "link" } },
                        [
                          _c("i", { class: item.icon }),
                          _vm._v(
                            "\n              " +
                              _vm._s(item.label) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }