<template>
  <b-form class="user-form" @submit.prevent="onUserSubmit">
    <b-row>
      <b-col class="col-12 mb-4 text-center">
        <b-avatar
          button
          class="mb-2 avatar-edit"
          badge-variant="transparent"
          variant="light"
          size="5em"
          :src="avatarUrl"
          @click="onAvatarClick"
        >
          <template #badge v-if="avatarUrl">
            <i class="simple-icon-camera"></i>
          </template>
        </b-avatar>
        <h5 v-if="fullName.trim()">{{ fullName }}</h5>
        <h5 v-else>[Name]</h5>
        <input
          id="fileUpload"
          ref="avatarFile"
          type="file"
          accept="image/jpg,image/png"
          @change="onAvatarFileChange"
          hidden
        />
      </b-col>
      <b-col class="col-md-6 mb-3">
        <base-input
          label="First Name:"
          placeholder="First Name"
          v-model="newUser.firstName"
          validate
          required
          :v="$v.newUser.firstName"
          :error="setError($v.newUser.firstName, 'First Name')"
          :loading="loadingUser"
        ></base-input>
      </b-col>
      <b-col class="col-md-6 mb-3">
        <base-input
          label="Last Name:"
          placeholder="Last Name"
          v-model="newUser.lastName"
          validate
          required
          :v="$v.newUser.lastName"
          :error="setError($v.newUser.lastName, 'Last Name')"
          :loading="loadingUser"
        ></base-input>
      </b-col>
      <b-col class="col-md-6 mb-3">
        <base-input
          v-if="hasLeaderRole"
          label="Team:"
          placeholder="Team"
          v-model="selectedTeam.name"
          validate
          required
          readonly
          :v="$v.newUser.team"
          :error="setError($v.newUser.team, 'Team')"
          :loading="loadingUser"
        ></base-input>
        <base-selection
          v-else
          label="Team:"
          placeholder="Team"
          v-model="selectedTeam"
          itemValue="name"
          :options="allTeams"
          validate
          required
          readonly
          :v="$v.newUser.team"
          :error="setError($v.newUser.team, 'Team')"
          :loading="loadingUser"
          class="team-selection"
        ></base-selection>
      </b-col>
      <b-col class="col-md-6 mb-3">
        <base-selection
          label="Role:"
          placeholder="Role"
          v-model="selectedRole"
          itemValue="name"
          :options="roles"
          validate
          required
          readonly
          :v="$v.newUser.role"
          :error="setError($v.newUser.role, 'Role')"
          :loading="loadingUser"
          class="role-selection"
        ></base-selection>
      </b-col>
      <b-col class="col-md-12 mb-3">
        <base-input
          label="Email:"
          placeholder="Email"
          v-model="newUser.email"
          validate
          required
          :readonly="editMode"
          type="email"
          :v="$v.newUser.email"
          :error="setError($v.newUser.email, 'Email')"
          :loading="loadingUser"
        ></base-input>
      </b-col>

      <transition name="fade">
        <b-col class="col-md-6 mb-3" v-if="resetPassword">
          <base-input
            label="Password:"
            placeholder="Password"
            v-model="newUser.password"
            validate
            required
            type="password"
            :v="$v.newUser.password"
            :error="setError($v.newUser.password, 'Password')"
            :loading="loadingUser"
          ></base-input>
        </b-col>
      </transition>

      <transition name="fade">
        <b-col class="col-md-6 mb-3" v-if="resetPassword">
          <base-input
            label="Confirm Password:"
            placeholder="Confirm Password"
            v-model="newUser.confirmPassword"
            validate
            required
            type="password"
            :v="$v.newUser.confirmPassword"
            :error="setError($v.newUser.confirmPassword, 'Confirm Password')"
            :loading="loadingUser"
          ></base-input>
        </b-col>
      </transition>
    </b-row>
    <div class="d-flex">
      <div class="align-self-center">
        <b-form-checkbox
          v-if="editMode"
          id="reset-password-checkbox"
          v-model="resetPassword"
          name="reset-password-checkbox"
          :value="true"
          :unchecked-value="false"
          class="align-items"
        >
          Reset password?
        </b-form-checkbox>
      </div>
      <div class="align-self-center ml-auto">
        <b-button variant="light" size="sm" @click="onCancelClick">
          <span class="label">Cancel</span>
        </b-button>
        <processing-button
          variant="primary"
          size="sm"
          :label="editMode ? 'Update' : 'Save'"
          class="ml-2"
          :processing="processing"
        ></processing-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Selections/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import { isPasswordValid, errorMessages } from '@/utils/validators'
import { userRoles } from '@/data/admin'
import { role } from '@/constants/config'
export default {
  components: {
    BaseInput,
    BaseSelection,
    ProcessingButton,
    PulseLoader
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      loadingUser: false,
      newUser: this.createNewUser(),
      newUserForm: this.createNewUserForm(),
      teams: [],
      selectedTeam: null,
      selectedRole: null,
      roles: userRoles,
      avatarUrl: '',
      resetPassword: false,
      processing: false,
      errorMessages
    }
  },
  validations() {
    let self = this
    if (!self.resetPassword) {
      return {
        newUser: {
          email: { required, email, emailMinLength: minLength(4) },
          firstName: { required },
          lastName: { required },
          team: { required },
          role: { required }
        }
      }
    } else {
      return {
        newUser: {
          email: { required, email, emailMinLength: minLength(4) },
          firstName: { required },
          lastName: { required },
          team: { required },
          role: { required },
          password: {
            required,
            passwordMaxLength: maxLength(16),
            passwordMinLength: minLength(8),
            isPasswordValid: isPasswordValid
          },
          confirmPassword: {
            samePassword: sameAs('password')
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['fetchUser', 'addUser', 'updateUser']),
    createNewUser() {
      return {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        avatar: null,
        password: '',
        confirmPassword: '',
        team: null,
        role: null
      }
    },
    createNewUserForm() {
      return new FormData()
    },
    getUser() {
      let self = this
      self.loadingUser = true
      const user = self.allUsers.find((user) => user.id === self.userId)
      self.newUser.id = user.id
      self.newUser.firstName = user.first_name
      self.newUser.lastName = user.last_name
      self.newUser.email = user.email
      self.newUser.avatar = user.avatar
      self.avatarUrl = user.avatar
      self.newUser.role = self.roles.find((role) => role.name === user.role).id
      self.newUser.team = user.team_id

      self.selectedTeam = {
        id: self.newUser.team,
        name: self.allTeams.find((team) => team.id === self.newUser.team).name
      }

      self.selectedRole = {
        id: self.newUser.role,
        name: user.role
      }
      self.loadingUser = false
    },
    onAvatarClick() {
      this.$refs['avatarFile'].click()
    },
    onAvatarFileChange(event) {
      let self = this
      let files = event.target.files
      const file = files[0]
      self.newUser.avatar = file
      self.avatarUrl = URL.createObjectURL(file)
    },
    setError(item, name) {
      if (item.$dirty) {
        let self = this
        let errorMessage = ''
        let errorMessages = self.errorMessages
        if (item.required === false) {
          errorMessage = `${name} ${errorMessages.required}`
          return errorMessage
        }

        if (item.email === false) {
          errorMessage = `${errorMessages.email}`
          return errorMessage
        }

        if (item.emailMinLength === false) {
          errorMessage = `${errorMessages.emailMinLength}`
          return errorMessage
        }

        if (item.passwordMaxLength === false) {
          errorMessage = `${errorMessages.passwordMaxLength}`
          return errorMessage
        }

        if (item.passwordMinLength === false) {
          errorMessage = `${errorMessages.passwordMinLength}`
          return errorMessage
        }

        if (item.isPasswordValid === false) {
          errorMessage = `${errorMessages.isPasswordValid}`
          return errorMessage
        }

        if (item.samePassword === false) {
          errorMessage = `${errorMessages.samePassword}`
          return errorMessage
        }
      }
    },
    onCancelClick() {
      let self = this
      self.$emit('userCancelled')
    },
    async onUserSubmit() {
      this.$v.newUser.$touch()
      // return if form error is captured
      if (this.$v.newUser.$pending || this.$v.newUser.$error) return

      // submit form
      let self = this
      let user = self.newUser
      let userForm = self.newUserForm

      userForm.append('first_name', user.firstName)
      userForm.append('last_name', user.lastName)
      userForm.append('email', user.email)
      userForm.append('team_id', self.selectedTeam.id)
      userForm.append('role', self.selectedRole.name)

      if (self.avatarUrl && self.isAvatarUrlLocal) {
        userForm.append('avatar', user.avatar)
      }

      if (self.resetPassword) {
        userForm.append('password', user.password)
        userForm.append('confirm-password', user.confirmPassword)
      }

      self.processing = true

      if (self.editMode) {
        userForm.append('id', user.id)
        await self.updateUser(userForm)
      } else {
        await self.addUser(userForm)
      }

      self.processing = false
      self.formChanged = false
      self.$emit('userSaved', true)
    },
    async resetUserData() {
      let self = this
      self.newUser = self.createNewUser()
      self.newUserForm = self.createNewUserForm()
      self.$v.$reset()
    }
  },
  computed: {
    ...mapGetters(['allUsers', 'allTeams', 'currentUser']),
    fullName() {
      let self = this
      return `${self.newUser.firstName} ${self.newUser.lastName}`
    },
    isAvatarUrlLocal() {
      let self = this
      let url = self.avatarUrl
      let regex = /^blob:/
      return regex.test(url)
    },
    hasLeaderRole() {
      let self = this
      return self.currentUser && self.currentUser.role === role.leader
    }
  },
  created() {
    let self = this
    if (self.editMode) {
      self.getUser()
    } else {
      self.selectedTeam = {
        id: self.currentUser.teamId,
        name: self.currentUser.team
      }
    }

    self.resetPassword = !self.editMode
  },
  watch: {
    selectedTeam(newVal) {
      this.newUser.team = newVal.id
    },
    selectedRole(newVal) {
      this.newUser.role = newVal.id
    }
  }
}
</script>
