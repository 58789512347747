<template>
  <div class="users">
    <div class="d-flex mb-2">
      <div class="align-self-center">
        <base-input
          placeholder="Search user..."
          v-model="filter"
          type="search"
          class="search-user-input"
        ></base-input>
      </div>
      <div class="align-self-center ml-auto">
        <b-button size="sm" variant="danger" class="text-uppercase mr-2" @click="onNewUserClick"
          >Add New User</b-button
        >
        <b-button
          size="sm"
          variant="outline-danger"
          class="text-uppercase"
          @click="onUserImportClick"
          >Import Users</b-button
        >
      </div>
    </div>

    <b-row>
      <b-colxx xxs="12">
        <b-card class="mb-4 stats-table" title="Users">
          <action-table
            v-if="allUsers"
            :key="reloadUserTable"
            :items="allUsers"
            :fields="userDataFields"
            :perPage="6"
            :filter="filter"
            :filterOn="filterOnFields"
            hasActions
            hasUnblockAction
            actionTooltip="full_name"
            @itemUnblocked="onItemUnblock"
            @itemClicked="onItemClick"
            @itemDeleted="onItemDelete"
          ></action-table>
        </b-card>
      </b-colxx>
    </b-row>

    <!-- Create User -->
    <b-modal
      v-model="showNewModal"
      size="lg"
      hide-header
      hide-footer
      content-class="user-edit-modal"
    >
      <user-form @userSaved="onUserSave" @userCancelled="onUserCancel"></user-form>
    </b-modal>

    <!-- Edit User -->
    <b-modal
      v-model="showEditModal"
      size="lg"
      hide-header
      hide-footer
      content-class="user-edit-modal"
    >
      <user-form
        editMode
        :userId="selectedUserId"
        @userSaved="onUserSave"
        @userCancelled="onUserCancel"
      ></user-form>
    </b-modal>

    <!-- Import Users Modal -->
    <b-modal id="userImportModal" ref="userImportModal" hide-header hide-footer>
      <h3 class="mb-4">Import users</h3>
      <b-form @submit.prevent="onUserImportSubmit" class="av-tooltip tooltip-label-bottom">
        <b-row>
          <b-col class="col-12 mb-4">
            <b-input-group>
              <label class="pl-0 mb-2 col-12">Select user file: </label>
              <b-form-file
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="onFileChange"
              ></b-form-file>
            </b-input-group>
          </b-col>
          <div class="col-12">
            <b-button variant="primary" size="lg" class="float-right" type="submit">
              <span class="label">Save</span>
            </b-button>
          </div>
        </b-row>
      </b-form>
    </b-modal>

    <!-- Confirm Action -->
    <action-confirm-modal
      :key="reloadActionTable"
      actionButton="Delete"
      actionButtonVariant="danger"
      :showModal.sync="showConfirmModal"
      :processing="processing"
      @modalTrigger="onModalTrigger"
      @actionConfirmed="onActionConfirm"
      @actionCanceled="onActionCancel"
      class="delete-user-modal"
    >
      <template v-if="deletedUser" v-slot:body>
        <h6>
          Are you sure you want to delete
          <span class="deleted-user-name">{{ deletedUser.full_name }}</span
          >?
        </h6>
        <p>This action cannot be undone once confirmed.</p>
      </template>
    </action-confirm-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseInput from '@/components/Common/BaseInput.vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import { userDataFields, userRoles, userFilterFields } from '@/components/Dashboard/config'
import ActionTable from '@/components/Tables/ActionTable.vue'
import UserForm from '@/components/Form/UserForm.vue'
import ActionConfirmModal from '@/components/Modals/ActionConfirmModal.vue'
import { validationMixin } from 'vuelidate'
const { required, email, minLength, sameAs } = require('vuelidate/lib/validators')
import { helperMixin } from '@/mixins/helperMixin'
export default {
  components: {
    BaseInput,
    ScaleLoader,
    ActionTable,
    UserForm,
    ActionConfirmModal
  },
  mixins: [validationMixin, helperMixin],
  computed: {
    ...mapGetters(['allUsers', 'allTeams'])
  },
  data() {
    return {
      userDataFields,
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      filter: null,
      filterOn: [],
      filterOnFields: userFilterFields,
      loader: {
        color: '#2d7281'
      },
      userModal: {
        id: 'userModal',
        title: '',
        content: {
          id: null,
          firstName: null,
          lastName: null,
          email: null,
          avatar: null,
          password: '',
          confirmPassword: '',
          teamId: null,
          role: null
        }
      },
      userImportFile: null,
      userRoles,
      avatarUrl: null,
      resetPassword: false,
      isEditUser: true,
      isAdmnin: false,
      serverUrl: config.VUE_APP_SERVER_URL || process.env.VUE_APP_SERVER_URL,
      selectedUser: {
        id: null,
        firstName: null,
        lastName: null
      },
      actionTooltip: '',
      reloadUserTable: 0,
      selectedUserId: null,
      deletedUser: null,
      showEditModal: false,
      showNewModal: false,
      showConfirmModal: false,
      processing: false,
      reloadActionTable: 0
    }
  },
  methods: {
    ...mapActions([
      'fetchTeams',
      'fetchAllUsers',
      'addUser',
      'updateUser',
      'deleteUser',
      'unblockUser'
    ]),
    async getAllTeams() {
      await this.$store.dispatch('fetchTeams')
    },
    async getAllUsers() {
      await this.$store.dispatch('fetchAllUsers')
    },
    setTooltipText(item) {
      let self = this
      if (self.actionTooltip && _.has(item, self.actionTooltip)) {
        return item[self.actionTooltip]
      } else {
        return self.actionTooltip
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onNewUserClick() {
      let self = this
      self.showNewModal = true
    },
    onAvatarFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      const file = files[0]
      this.userModal.content.avatar = file
      this.avatarUrl = URL.createObjectURL(file)
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      const file = files[0]
      this.userImportFile = file
    },
    onUserImportClick() {
      this.$refs['userImportModal'].show()
    },
    async onUserImportSubmit() {
      this.$refs['userImportModal'].hide()
      let formData = new FormData()
      let userFile = this.userImportFile
      formData.append('file', userFile)

      await this.$store.dispatch('submitTeamUsersFile', formData).then((response) => {
        this.processing = false
        this.$notify(response.status, 'Team users are imported.', response.message, {
          duration: 2000,
          permanent: false
        })
      })

      this.getAllUsers()
    },
    convertFormDataToJson(formData, avatarUrl) {
      var object = {}
      formData.forEach(function (value, key) {
        switch (key) {
          case 'id':
            object[key] = parseInt(value)
            break
          case 'first_name':
            object['firstName'] = value
            break
          case 'last_name':
            object['lastName'] = value
            break
          case 'email':
            object['email'] = value
            break
          case 'avatar':
            object[key] =
              avatarUrl === null ? this.getServerAvatarUrl(this.serverUrl, 'avatar') : avatarUrl
            break
          case 'team_id':
            object['teamId'] = value
            break
          case 'role':
            object['role'] = value
            break
          case 'password':
            object['password'] = value
            break
          case 'confirm_password':
            object['confirmPassword'] = value
            break
          default:
            object[key] = value
            break
        }
      })
      return JSON.parse(JSON.stringify(object))
    },
    getServerAvatarUrl(serverUrl, userName) {
      return `${serverUrl}/storage/${userName.toLowerCase()}.png`
    },
    async onUserSave() {
      let self = this
      self.showNewModal = false
      self.showEditModal = false
      await self.getAllUsers()
      self.reloadActionTable += 1
    },
    onUserCancel() {
      let self = this
      self.showNewModal = false
      self.showEditModal = false
    },
    onItemClick(item) {
      let self = this
      self.selectedUserId = item.id
      self.showEditModal = true
    },
    onItemDelete(item) {
      let self = this
      self.deletedUser = item
      self.onModalTrigger(true)
    },
    async onItemUnblock(item) {
      let self = this
      self.selectedUserId = item.id
      let userForm = new FormData()
      userForm.append('id', self.selectedUserId)
      userForm.append('first_name', item.first_name)
      userForm.append('last_name', item.last_name)
      await self.unblockUser(userForm)
      item.active = 1
    },
    reloadUsers() {
      let self = this
      self.getAllUsers()
      self.reloadUserTable += 1
    },
    async onActionConfirm() {
      let self = this
      let user = self.deletedUser
      self.processing = true
      await self.deleteUser({ id: user.id, name: user.full_name })
      self.processing = false
      self.onModalTrigger(false)
      self.reloadUsers()
    },
    async onModalTrigger(value) {
      this.showConfirmModal = value
    },
    onActionCancel() {
      this.onModalTrigger(false)
    }
  },
  async created() {
    await this.getAllTeams()
    await this.getAllUsers()
  },
  validations() {
    if (!this.resetPassword) {
      if (this.isAdmnin) {
        return {
          userModal: {
            content: {
              firstName: {
                required
              },
              lastName: {
                required
              },
              email: {
                required,
                email,
                minLength: minLength(4)
              }
            }
          }
        }
      } else {
        return {
          userModal: {
            content: {
              firstName: {
                required
              },
              lastName: {
                required
              },
              email: {
                required,
                email,
                minLength: minLength(4)
              },
              teamId: {
                required
              },
              role: {
                required
              }
            }
          }
        }
      }
    } else {
      return {
        userModal: {
          content: {
            firstName: {
              required
            },
            lastName: {
              required
            },
            email: {
              required,
              email,
              minLength: minLength(4)
            },
            password: {
              required
            },
            confirmPassword: {
              sameAsPassword: sameAs('password')
            },
            teamId: {
              required
            },
            role: {
              required
            }
          }
        }
      }
    }
  }
}
</script>
