<template>
  <div class="sidebar">
    <div class="main-menu">
      <vue-perfect-scrollbar
        class="scroll"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
      >
        <ul class="list-unstyled">
          <li
            v-for="(item, index) in menuItems"
            :key="`menu-${index}`"
            :class="{ active: selectedMenuId === item.id }"
            @click="onMenuClick(item.id)"
          >
            <router-link :to="item.to" custom>
              <span role="link" class="menu-link">
                <i :class="item.icon" />
                {{ item.label }}
              </span>
            </router-link>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedMenuId: 0
    }
  },
  methods: {
    onMenuClick(menuId) {
      this.selectedMenuId = menuId
    },
    async setDefaultSelected() {
      let self = this
      if (self.menuItems.length > 0) {
        self.selectedMenuId = self.menuItems[0].id
      }
    },
    checkAdmin(menuItem) {
      let visible = true
      let self = this
      if (menuItem.adminOnly) {
        visible = self.isAdmin
      }
      return visible
    },
    setSelectedMenu(route) {
      let self = this
      const menuItemId = route.meta.id
      const menuItem = self.menuItems.find(item => item.id === menuItemId)
      if (menuItem) {
        self.selectedMenuId = menuItemId
      }
    }
  },
  created() {
    let self = this
    self.setSelectedMenu(self.$route)
  },
  watch: {
    $route(to) {
      let self = this
      self.setSelectedMenu(to)
    }
  }
}
</script>
