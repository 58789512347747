<template>
  <div id="app-container" class="app-container rounded">
    <div v-if="currentUser">
      <sidebar :menuItems="filteredMenu" />
      <!-- <div class="dashboard-main"> -->
      <main>
        <div class="container-fluid">
          <transition name="zoom" mode="out-in">
            <router-view />
          </transition>
        </div>
      </main>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import Sidebar from '@/containers/Sidebar'
import { adminMenuItems } from '@/constants/menu'
import { mapGetters } from 'vuex'
export default {
  components: {
    sidebar: Sidebar
  },
  data() {
    return {
      menuItems: adminMenuItems
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    filteredMenu() {
      let self = this
      const role = self.currentUser.role
      return self.menuItems.filter((item) => item.role.includes(role))
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
