var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container rounded", attrs: { id: "app-container" } },
    [
      _vm.currentUser
        ? _c(
            "div",
            [
              _c("sidebar", { attrs: { menuItems: _vm.filteredMenu } }),
              _vm._v(" "),
              _c("main", [
                _c(
                  "div",
                  { staticClass: "container-fluid" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "zoom", mode: "out-in" } },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }